.data-table-container {
    max-width: 100%;
    overflow-x: auto;
    padding: 1em;
    margin-top: 4px;
    margin-left: 5%;
   
   
  }
  
  .data-table {
    max-width: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd;
    font-family: Arial, sans-serif;
    margin-top: 30px;
  }
  
  .data-table th, .data-table td {
    border: 1px solid #ddd;
    padding: 0.75em;
    text-align: left;
    font-size: small;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .data-table th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  .data-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .data-table tr:hover {
    background-color: #f1f1f1;
  }
  .data-table-container .p {
    font-size: 100px;

  }

  .data-table button {
    font-size: smaller;
  }

  @media (max-width:767px){
    .data-table th, .data-table td {
        border: 1px solid #ddd;
        padding: 0.5em;
        text-align: left;
        font-size:x-small;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      }
      .data-table-container {
        max-width: 100%;
        overflow-x: auto;
        padding: 1em;
        margin-top: 4px;
        margin-left: 0.2%;
       
       
      }
      .data-table tfoot {
        font-size: x-small;
      }
    

    
  }
  