@media (max-width: 480px) {
  .offcanvas-collapse {
    position: fixed;
    top: 56px; /* Height of navbar */
    bottom: 0;
    left: 100%;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: #f8f9fa;
    transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
  }
  .offcanvas-collapse.open {
    visibility: visible;
    transform: translateX(-100%);
  }
}

.bg-custom-light {
  background-color: #e7e7e7;
}

.bg-dim {
  background-color: rgba(0, 0, 0, 0.6);
}

.cover {
  object-fit: cover;
}

.opacity-6 {
  opacity: 0.6;
}

.opacity-5 {
  opacity: 0.5;
}
.txt-color{
  color: #B33333;
  font-weight: bolder;

}
.image-vertical-scroller {
  overflow-y: auto;
  max-height: 600px;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.image-vertical-scroller::-webkit-scrollbar {
  display: none;
}

.h-scroller {
  position: relative;
  z-index: 2;
  height: 3.75rem;
  overflow-y: hidden;
}

.h-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  color: rgba(255, 255, 255, .75);
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.h-underline .h-link {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: .875rem;
  color: #6c757d;
}

loader {
  width: 53px;
  height: 73px;
  border: 5px dotted red;
  border-style: solid solid dotted dotted;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}

.loader::after {
  content: "Yükleniyor";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 30%;
  margin: auto;
  border: 4px dotted #f00;
  border-style: solid solid dotted;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  animation: rotationBack 1s linear infinite;
  transform-origin: center center;
}
.cartt {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  background-color: #fff;
}
.containerd {
  max-width: 800px;
  margin: 100px auto;
  padding: 0 20px;
}

.containerx {
  max-width: 100px;
  margin:  auto;
  padding: 0 220px;
}
.productd {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;

}
.header-phone {
  border:1px solid

}

.productd img {
  width: 80px;
  height: 100px;
  margin-right: 10px;
}

.productd-info {
  flex-grow: 1;
 
}

.productd-info h3 {
  margin: 0;
  
}
.productd-info h5 {
  font-size: medium;
  font-weight: 600;
  
}

.productd-info p {
  margin: 5px 0;

}
.remove-btn {
  background-color: #f44336;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  font-size: smaller;
  margin-bottom: 2px;
}

.remove-btn:hover {
  background-color: #d32f2f;
}

.total {
  margin-top: 20px;
  text-align: right;
}

.checkout-btn {
  background-color: #4caf50;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.checkout-btn:hover {
  background-color: #388e3c;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}
