/* src/components/UserRegister.css */

.user-register {
  max-width: 100%;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 50%;
 
 padding: 1%;
   border: 1px solid #ddd;
   border-radius: 8px;
   background-color: #f9f9f9;
}

.user-register h1 {
  font-size: 2em;
  margin-bottom: 20px;
  text-align: center;
}
.user-register h6 {
  font-size: 0.8em;
  margin-bottom: 5px;
  text-align:start;
  font-weight: bold;
}


.form-group {
  margin-bottom: 20px;
  margin-top: 10px;
  
}

.form-group label {
  display: block;
  font-weight:bold;
  margin-bottom: 2px;
  font-size:0.8em;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.error {
  color: red;
  font-size: 0.9em;
  display: block;
  margin-top: 5px;
}

.submit-button {
 display: block;
  width: 20%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 1.1em;
  cursor: pointer;
  float: right;
  
}
.order-item {
  display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
   
}
.item-name {
  flex: 2;
}

.item-quantity, .item-price {
  flex: 1;
  text-align: right;
}

.submit-button:hover {
  background-color: #0056b3;
}
.select-options {
  width: 50%;
  background-color:white;
  border-radius: 6px;
  color:black;
  font-weight: 500;
  font-size: small;
  margin-bottom: 5px;
  line-height: normal;
  min-height: 40px;
 
  


}

@media (max-width:768px) {
  .user-register {
    width: 90%;
    margin: 0 auto;
     padding: 20px;
     border: 1px solid #ddd;
     border-radius: 8px;
     background-color: #f9f9f9;

}
.form-group label {
  display: block;
  font-weight: normal;
  margin-bottom: 5px;
  font-size:small;
}
.submit-button {
  display: block;
   width: 100%;
   padding: 10px;
   border: none;
   border-radius: 4px;
   background-color: #007bff;
   color: white;
   font-size: 1.1em;
   cursor: pointer;
   float: right;
   
 }
 .select-options {
  width: 100%;
  background-color:white;
  border-radius: 6px;
  color:black;
  font-weight: 200;
  font-size: small;

}
.user-register h6 {
  font-size: 0.8em;
  margin-bottom: 5px;
  text-align:start;
  font-weight: normal;
}


  
}
